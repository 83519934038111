export class FilterModel {
  pageNumber?: string;
  pageSize?: string;
  whereCondition?: string;
  orderBy?: string;
  keywordFilter?: string;
  stateID?: string;
  userID?: string;
  columnIndex?: string;
  productId?: number;
  actionTypes?: string;
  carrierCategories?: string;
  actionSatus?: string;
  listOfProduct?: string;
  emailKey?: string;
  isActionRequired?: boolean;
  oprator?: string;
  fromDate?: string;
  toDate?: string;
  listOfType?: string;
  systemUserCondition?: string;
  briefingTypesId?: string;
  briefingSubtypesId?: string;
  docPath?: string;
  stateName?: string;
  docType?: string;
  parentId?: string;
  level?: string;
  isClicktoFolderOpen?: boolean;
  userOtherFavoriteId?: any;
  briefingCategoryId?: any;
  actionName: string;
  DataType: string;
  logId: number;
  status: number;
  listOfCompanyId?: string;
  productUnassingForUser?: string;
  providerId?: string;
  months?: string;
  years?: string;
  state?: string;
  claimMasterId?: string;
  companyId?: string;
  customProductId?: string;
  actionId?:number;
  deadlineDate?:string;
  description?:string;
  email?:string;
  updateId?:number;
  briefingId?:string;
  statusId?:string;
  section?:string;
  freeId?:string;
  product?:string;
}

export class CarrierFilterModel {
  orderBy: string;
  keywordFilter: string;
  fromDate: string;
  todate: string;
  updateStatus: string;
  userID: number;
  ID: number;
  Status: number;
  filterFavourite: number;
  newCarrierID: number;
  carrierFilter: string;
}


export class SysFilter {
  systemUserCondition?: string;
}

export class Filter911Model {
  pageNumber?: number;
  pageSize?: number;
  orderBy?: string;
  userID?:string | number;
  stateID?: string | number;
  countyID?: string | number;
  cityID?: string | number;
  scopeID?: string | number;
  filterText?: string;
  isFutureChanges:boolean;
  isActive:boolean;
  isFavorite?:number;
  selectedItem?:string;
  fromDate?: string;
  toDate?: string;
  fromDate1?: string;
  toDate1?: string;
}


export class BriefingMasterFilterModel {
  pageNumber?: string;
  pageSize?: string;
  whereCondition?: string;
  orderBy?: string;
  keywordFilter?: string;
  stateID?: string;
  userID?: string;
  columnIndex?: string;
  productId?: number;
  actionTypes?: string;
  carrierCategories?: string;
  actionSatus?: string;
  listOfProduct?: string;
  emailKey?: string;
  isActionRequired?: boolean;
  oprator?: string;
  fromDate?: string;
  toDate?: string;
  listOfType?: string;
  systemUserCondition?: string;
  briefingTypesId?: string;
  briefingSubtypesId?: string;
  docPath?: string;
  stateName?: string;
  docType?: string;
  parentId?: string;
  level?: string;
  isClicktoFolderOpen?: boolean;
  userOtherFavoriteId?: any;
  briefingCategoryId?: any;
  actionName: string;
  DataType: string;
  logId: number;
  status: number;
  listOfCompanyId?: string;
  productUnassingForUser?: string;
  providerId?: string;
  months?: string;
  years?: string;
  state?: string;
  claimMasterId?: string;
  companyId?: string;
  customProductId?: string;
  actionId?:number;
  deadlineDate?:string;
  description?:string;
  email?:string;
  updateId?:number;
  briefingId?:string;
  statusId?:string;
  section?:string;
  freeId?:string;
  product?:string;

  sourceFromDate?: string;
  sourceToDate?: string;
  acquiredFromDate?: string;
  acquiredToDate?: string;
}
