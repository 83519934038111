
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Response, Headers, ResponseContentType, RequestMethod } from '@angular/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class I360Service {
    AppUrl: string = "";
    constructor(private _http: Http, private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        this.setAPIUrl();
    }

    setAPIUrl() {
        if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
            this.AppUrl = sessionStorage.getItem("BaseURL") || '';
        }
    }

    i360StateCommissionWebSitesAddUpdate(TokenKey: string, stateCommissionModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360StateCommissionWebSitesAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, stateCommissionModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360StateCommissionWebSitesGet(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360StateCommissionWebSitesGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360StateCommissionWebSitesFindbyStateCommissionId(TokenKey: string, stateCommissionId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360StateCommissionWebSitesFindbyStateCommissionId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, stateCommissionId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360StateCommissionWebSitesDelete(TokenKey: string, stateCommissionId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360StateCommissionWebSitesDelete';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, stateCommissionId, httpOptions)
            .pipe(catchError(this.handleError));
    }


    i360CarrierTariffsAddUpdate(TokenKey: string, carrierTariffsModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360CarrierTariffsAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, carrierTariffsModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360CarrierTariffsGet(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360CarrierTariffsGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360CarrierTariffsFindbyCarrierId(TokenKey: string, carrierId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360CarrierTariffsFindbyCarrierId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, carrierId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360CarrierTariffsDelete(TokenKey: string, carrierId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360CarrierTariffsDelete';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, carrierId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BriefingMasterAddUpdate(TokenKey: string, briefingMasterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BriefingMasterAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, briefingMasterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BriefingMasterGet(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BriefingMasterGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BriefingMasterFindbyBriefingId(TokenKey: string, briefingId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BriefingMasterFindbyBriefingId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, briefingId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BriefingMasterDelete(TokenKey: string, briefingId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BriefingMasterDelete';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, briefingId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360477DataAddUpdate(TokenKey: string, data477Model: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360477DataAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, data477Model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360477DataGet(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360477DataGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360477DataFindbyDataId(TokenKey: string, dataId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360477DataFindbyDataId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, dataId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360477DataDelete(TokenKey: string, dataId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360477DataDelete';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, dataId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BulletinTypeGet(TokenKey: string): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BulletinTypeGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BulletinSubTypeGet(TokenKey: string): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BulletinSubTypeGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360DashboardCount(TokenKey: string): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360DashboardCount';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360MenuGroupGet(TokenKey: string, userId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Menu/i360MenuGroupGet';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, userId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360FrontBriefingViewByPaged(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360FrontBriefingViewByPaged';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GenerateByPassAuthenticationKey(TokenKey: string): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/GenerateByPassAuthenticationKey';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    BriefingInstantEmailSendById(TokenKey: string, briefingId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/BriefingInstantEmailSendById/' + briefingId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    DownloadDocument(TokenKey: string, docPath: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/DownloadDocument';
        return this._http.post(this.AppUrl + data, docPath, {
            method: RequestMethod.Post, responseType: ResponseContentType.Blob,
            headers: new Headers({ 'Token': TokenKey })
        }).pipe(
            map(
                (res) => {

                    return new Blob([res.blob()], { type: 'application/octet-stream' });
                }));
    }

    i360UserBriefingAdd(TokenKey: string, userBriefingModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserBriefingAdd';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, userBriefingModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360OnlineUserRegistration(user, TokenKey: string): Observable<any> {
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + 'api/Inteserra360/i360OnlineUserRegistration', user, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360FavoriteBriefingByPaged(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360FavoriteBriefingByPaged';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserBriefingRemoveFromFavotire(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserBriefingRemoveFromFavotire';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    // i360UserLibraryFindParentId(TokenKey: string, Product: any, UserId: any, ParentId: any) {
    //     this.setAPIUrl();
    //     let data = 'api/Inteserra360/i360UserLibraryFindParentId/' + Product + '/' + UserId + '/' + ParentId;
    //     const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
    //     return this._httpClient.get(this.AppUrl + data, httpOptions)
    //         .toPromise()
    //         .then(res =>
    //             <TreeNode[]>res
    //         );
    // }

    i360UserLibraryFindParentId(TokenKey: string, Product: any, UserId: any, ParentId: any) {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserLibraryFindParentId/' + Product + '/' + UserId + '/' + ParentId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserLibraryFindChild(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserLibraryFindChild';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserLibraryAdd(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserLibraryAdd';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserFolderAdd(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserFolderAdd';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserLibraryFolderUpdate(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserLibraryFolderUpdate';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserLibraryFolderDelete(TokenKey: string, id: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserLibraryFolderDelete/' + id;
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    DownloadFileForLibrary(TokenKey: string, fileName: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/DownloadFileForLibrary';
        return this._http.post(this.AppUrl + data, fileName, {
            method: RequestMethod.Post, responseType: ResponseContentType.Blob,
            headers: new Headers({ 'Token': TokenKey })
        }).pipe(
            map(
                (res) => {
                    return new Blob([res.blob()], { type: 'application/octet-stream' });
                }));
    }

    i360UserLibraryDelete(TokenKey: string, libraryId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserLibraryDelete/' + libraryId;
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, libraryId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserLibraryFindByLibraryId(TokenKey: string, libraryId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserLibraryFindByLibraryId/' + libraryId;
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, libraryId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BriefingStateFindByBriefingId(TokenKey: string, BriefingId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BriefingStateFindByBriefingId/' + BriefingId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360FrontSolutionTracIt(TokenKey: string, UserId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Menu/i360FrontSolutionTracIt/' + UserId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserLibraryFileCount(TokenKey: string, UserId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Menu/i360UserLibraryFileCount/' + UserId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360GetUserId(TokenKey: string, userId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Menu/i360GetUserId/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360StateCommissionWebSitesForFront(TokenKey: string): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360StateCommissionWebSitesForFront';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, {}, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360CarrierTariffsForFront(TokenKey: string): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360CarrierTariffsForFront';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, {}, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360VerifyEmailAddress(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Menu/i360VerifyEmailAddress';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360CompanyDirectoryFindByUserId(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360CompanyDirectoryFindByUserId';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360NewsFindByPaged(TokenKey: string, filterModel: any): Observable<any> {

        this.setAPIUrl();
        let data = 'api/Inteserra360/i360NewsFindByPaged';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360Top9NewsFindByPaged(TokenKey: string, filterModel: any): Observable<any> {

        this.setAPIUrl();
        let data = 'api/Menu/i360NewsFindByPaged';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserLibraryReminderAddUpdate(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360UserLibraryReminderAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360WebinarLibraryGet(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360WebinarLibraryGet';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360InteserraResourcesGet(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360InteserraResourcesGet';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360WhitePapersGet(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360WhitePapersGet';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360LibraryReminderFindByLibraryReminderId(TokenKey: string, libraryReminderId: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360LibraryReminderFindByLibraryReminderId/' + libraryReminderId;
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, libraryReminderId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    BulletinTypeSubscriptionGet(TokenKey: string, userId): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/BulletinTypeSubscriptionGet/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserOtherFavoriteAddUpdate(TokenKey: string, model: any): Observable<any> {

        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserOtherFavoriteAddUpdate';

        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserOtherFavoriteByPaged(TokenKey: string, filterModel: any): Observable<any> {

        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserOtherFavoriteByPaged';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360UserOtherFavoriteDelete(TokenKey: string, userOtherFavoriteId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360UserOtherFavoriteDelete/' + userOtherFavoriteId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, userOtherFavoriteId, httpOptions)
            .pipe(catchError(this.handleError));
    }

    ExportExcelMailData(TokenKey: string, briefingId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/ExportForBriefingEmailByBriefingId/' + briefingId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetBriefingDashboardCount(TokenKey: string): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/GetBriefingDashboardCount';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360DuplicateBriefingAdd(TokenKey: string, briefingId: any, userId: any): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360DuplicateBriefingAdd/' + briefingId + '/' + userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    ExportBriefingReports(TokenKey: string, filterModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/ExportBriefingReports';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, filterModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360TrainingGetFront(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360TrainingGetFront';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360SpecialReportGetFront(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360SpecialReportGetFront';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    private handleError(error: Response | any) {
        console.error(error.message || error);
        return observableThrowError(error.status)
    }

    i360CustomProductDetailsGet(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();

        let data = 'api/Inteserra360/i360CustomProductDetailsFront';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360CustomProductGet(TokenKey: string, userId: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Menu/i360CustomProductGetFrontMenuList?userId='+userId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, null, httpOptions)
            .pipe(catchError(this.handleError));
    }

    BriefingTypeAddUpdate(TokenKey: string, BriefingAddUpdateModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/BriefingTypeAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, BriefingAddUpdateModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    DeleteBriefingType(TokenKey: string, carrierCatId: number): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/DeleteBriefingType/' + carrierCatId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetBriefTypeById(TokenKey: string, carrierCatId: number) {
        this.setAPIUrl();
        let data = 'api/Inteserra360/GetBriefTypeById/' + carrierCatId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BulletinType(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, orderBy: string): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BulletinType/' + PageNumber + '/' + PageSize + '/' + filterText + '/' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }
    
    BriefingSubTypeAddUpdate(TokenKey: string, BriefingSubAddUpdateModel: any): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/BriefingSubTypeAddUpdate';
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, BriefingSubAddUpdateModel, httpOptions)
            .pipe(catchError(this.handleError));
    }

    DeleteBriefingSubType(TokenKey: string, BulletinSubTypeId: number): Observable<any> {
        this.setAPIUrl();
        let data = 'api/Inteserra360/DeleteBriefingSubType/' + BulletinSubTypeId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    GetBriefSubTypeById(TokenKey: string, BulletinSubTypeId: number) {
        this.setAPIUrl();
        let data = 'api/Inteserra360/GetBriefSubTypeById/' + BulletinSubTypeId;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.get<any>(this.AppUrl + data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    i360BulletinSubType(TokenKey: string, PageNumber: number, PageSize: number, filterText: string, orderBy: string): Observable<any> {
        let model;
        this.setAPIUrl();
        let data = 'api/Inteserra360/i360BulletinSubType/' + PageNumber + '/' + PageSize + '/' + filterText + '/' + orderBy;
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }
    
    i360TaxExemptionFormGetFront(TokenKey: string, model: any): Observable<any> {
        this.setAPIUrl();
    
        let data = 'api/Inteserra360/i360TaxExemptionFormGetFront';
        const httpOptions = { headers: new HttpHeaders({ "Accept": "application/json", 'Token': TokenKey }) };
        return this._httpClient.post<any>(this.AppUrl + data, model, httpOptions)
            .pipe(catchError(this.handleError));
    }
}
