import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { iif } from 'rxjs';
import { CityMaster911Model } from 'src/Models/CityMaster911Model';
import { CountyMaster911Model } from 'src/Models/CountyMaster911Model';
import { AddLocation911Model, AddNewLocation911Model, Location911Model } from 'src/Models/Location911Model';
import { AddMain911Model, Main911Model } from 'src/Models/Main911Model';
import { StateMasterModel } from 'src/Models/stateMasterModel';
import { CityMasterService } from 'src/Services/city-master.service';
import { CountyMasterService } from 'src/Services/county-master.service';
import { Location911Service } from 'src/Services/location911.service';
import { Main911Service } from 'src/Services/main911.service';
import { StateMasterService } from 'src/Services/state-master.service';
import { CheckAuth } from 'src/Services/utility.service';

@Component({
  selector: 'app-createoredit-rate-main911',
  templateUrl: './createoredit-rate-main911.component.html',
  styleUrls: ['./createoredit-rate-main911.component.css']
})
export class CreateoreditRateMain911Component implements OnInit {
  @ViewChild('briefingDoc', { static: true }) briefingDoc;
  rateMain911Form: FormGroup;
  userID: any;
  SelectedTool: string;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;
  tokenKey: any;
  isSubmit: boolean = false;
  stateList: StateMasterModel[] = [];
  selectedState = [];
  countyList: CountyMaster911Model[] = [];
  selectedCounty = [];
  isEdit: boolean = false;
  dropdownDateFilter = [];
  dropdownSettingsDateFilter = {};

  dropdownDateCountyFilter = [];
  dropdownDateCityFilter = [];
  cityList: CityMaster911Model[] = [];
  selectedCity = [];
  scopeList = [];
  selectedScope = [];
  addMain911Detail: AddMain911Model;
  isExist: string = "true";
  isExists: boolean = true;
  countyName: string = "";
  cityName: string = "";
  isLocationStep: boolean = true;
  newLocation: AddNewLocation911Model;
  location911List: Location911Model[] = [];

  orderBy: string = 'StateId ASC';
  isAscending: boolean = false;
  upDowNameCSS: string = '';
  upDowStateNameCSS: string = '';
  upDowCountyNameCSS: string = '';
  upDowActiveCSS: string = '';
  PageNumber: number = 1;
  PageSize: number = 10;
  rateId = "";
  isloading: boolean = false;
  isRecord: boolean = false;
  isEditLocationStep: boolean = false;
  isState: boolean = true;
  dropdownSettingsStateFilter = {};
  countyId: number = 0;
  cityId: number = 0;
  todayDate = new Date();
  pattern = /^[ a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+(\s{0,1}[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ])*$/
  patternNumeric = /^[0-9]\d{0,2}(\.\d{3})*(,\d+)?$/
  dropdownSettingsCountyFilter = {};
  dropdownSettingsCityFilter = {};
  dropdownSettingsEditStateFilter = {};
  isErrorOnBriefingDoc: boolean;
  isErrorOnSupportedDoc: boolean;
  citationDoc: any;
  checkDoc: boolean = false;
  checkURLs: boolean = false;
  fileName: any;
  theFile: any;
  mainRate: any;
  validDates: any;
  AppUrl: string = "";
  module = '911';
  auditDate: any;
  auditMonth: any;
  deleteLocationID: any[] = [];

  listOfCountyIds = [];
  countysId: string = ' ';
  listOfCityIds = [];
  citysId: string = ' ';
  cityListForRemove = [];
  cityListForRemoveNew = [];
  isDuplicate: boolean = false;

  constructor(private _fb: FormBuilder, private checkAuth: CheckAuth
    , private activedRoute: ActivatedRoute,
    private router: Router, private countyService: CountyMasterService, private stateService: StateMasterService,
    private toastr: ToastrService, private cityService: CityMasterService, private main911Service: Main911Service,
    private locationService: Location911Service, private datePipe: DatePipe, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.tokenKey = sessionStorage.getItem('tokenValue');
    this.getState();
    this.getAllScope();
    this.tokenExpireCalculation(sessionStorage.getItem('appStartTime'), sessionStorage.getItem('tokenExpiryValue'));
    this.userID = +sessionStorage.getItem('AdminUserId');
    this.SelectedTool = sessionStorage.getItem('UserLoginTool');
    this.setAPIUrl();

    this.isDuplicate = sessionStorage.getItem('isDuplicate') == 'true' ? true : false;
    if (sessionStorage.getItem('isDuplicate') != null)
      sessionStorage.removeItem('isDuplicate');
  }

  tokenExpireCalculation(startDate: string, tokenExpiry: string) {
    this.checkAuth.tokenExpireCalculation(startDate, tokenExpiry);
  }
  checkUrl() {
    let value = this.rateMain911Form.value as any;;
    if (value.citationURL.trim() == "" || this.rateMain911Form.controls.citationURL.status == "INVALID") {
      this.checkDoc = false;
    }
    else {
      document.getElementById("citationDocument")['value'] = "";
      this.showMessage("For citation last modified detail will be saved to database.", 'Warning', 'warning')
      this.citationDoc = null;
      this.fileName = null;
    }

  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  ngOnInit() {
    this.dropdownSettingsDateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Scope(s) available',
      showCheckbox: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsStateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      disabled: false,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsEditStateFilter = {
      singleSelection: true,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No Jurisdiction(s) available',
      showCheckbox: false,
      disabled: true,
      enableFilterSelectAll: false
    };
    this.dropdownSettingsCountyFilter = {
      singleSelection: false,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No County(s) available',
      showCheckbox: true,
    };
    this.dropdownSettingsCityFilter = {
      singleSelection: false,
      text: "Select Filter(s)",
      enableSearchFilter: true,
      classes: "borderLessTextBoxGreen",
      badgeShowLimit: 2,
      noDataLabel: 'No City(s) available',
      showCheckbox: true,
    };
    this.rateMain911Form = this._fb.group({
      rateID: 0,
      cityId: [''],
      stateId: [''],
      countyId: [''],
      taxName: ['', Validators.compose([Validators.required, Validators.pattern(this.pattern)])],
      scopeID: ['', Validators.required],
      rate: [''],
      fee: [''],
      effectiveDate: ['', Validators.required],
      endDate: ['', Validators.required],
      whatTaxed: ['', Validators.compose([Validators.required, Validators.pattern(this.pattern)])],
      citationName: [''],
      citationURL: [''],
      citationDocName: [''],
      citationFileName: [''],
      notes: [''],
      auditDate: [''],
      auditMonth: [''],
      showFutureLaws: [false],
      isActive: [false]
    })
    this.activedRoute.params.subscribe((params: Params) => {
      if (params['id']) {
        if (params['id'] != null) {
          this.isEdit = true;
          this.rateId = params['id']
          this.GetMainById(params['id']);
        }
      }
    });
    this.rateMain911Form.controls.effectiveDate.valueChanges.subscribe((x) => {
      if (x) this.validDates = x;
      else this.validDates = new Date();
      this.rateMain911Form.controls.endDate.setValue("");
    })
  }
  getState() {
    this.stateService.GetStateMaster(this.tokenKey, this.module).subscribe(
      data => {
        this.stateList = data['state'];
        this.dropdownDateFilter = [];
        if (this.stateList.length > 0) {
          this.stateList.forEach(x => {
            this.dropdownDateFilter.push({ "id": x.stateId, "itemName": x.name })
          });
        }
      },
      error => { }
    );
  }
  GetCountyByStateId(stateId, isNewCounty) {
    this.countyService.GetCountyByStateId(this.tokenKey, stateId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.countyList = result['county'];
          this.dropdownDateCountyFilter = [];
          this.selectedCounty = [];
          if (!isNewCounty) {
            this.dropdownDateCityFilter = [];
            this.selectedCity = [];
          }
          this.countyList.forEach(x => {
            this.dropdownDateCountyFilter.push({ "id": x.countyId, "itemName": x.countyName })
          });
          if (!this.isEdit && isNewCounty && this.countyId != 0) {
            if (this.countyList.length > 0) {
              let data = this.countyList.filter(x => x.countyId == this.countyId)[0];
              if (data) {
                this.selectedCounty.push({ "id": data.countyId, "itemName": data.countyName })
                this.rateMain911Form.patchValue({
                  countyId: data.countyId
                });
              }
            }
          }
        }
      });
  }
  GetCityByCountyId(countyId, isNewCity) {
    this.cityService.GetCityByCountyIdMultiple(this.tokenKey, countyId, true).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.cityList = result['city'];
          if (this.cityList.length > 0 && this.selectedCity.length > 0 && this.listOfCityIds.length > 0) {
            for (let iCounter = 0; iCounter < result['city'].length; iCounter++) {
              let index: number = -1;
              index = this.selectedCity.findIndex(y => y.id == result['city'][iCounter].id);
              if (index == -1) {
                this.selectedCity.push(result['city'][iCounter]);
              }

              index = this.dropdownDateCityFilter.findIndex(y => y.id == result['city'][iCounter].id);
              if (index == -1) {
                this.dropdownDateCityFilter.push({ "id": result['city'][iCounter].id, "itemName": result['city'][iCounter].itemName })
              }
            }
            this.cityList = [];
            this.cityList = this.dropdownDateCityFilter;
          }
          else {
            this.dropdownDateCityFilter = [];
            this.selectedCity = [];
            this.listOfCityIds = [];
            this.cityList = result['city'];
            this.selectedCity = result['city'];
            this.selectedCity.forEach(x => {
              this.dropdownDateCityFilter.push({ "id": x.id, "itemName": x.itemName })
            });
          }

          for (let iCounter = 0; iCounter < result['city'].length; iCounter++) {
            if (this.listOfCityIds.length > 0) {
              this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, result['city'][iCounter].id)
              this.listOfCityIds.push(result['city'][iCounter].id);
            }
            else {
              this.listOfCityIds.push(result['city'][iCounter].id);
            }
          }

          this.citysId = ' ';
          if (this.listOfCityIds.length > 0) {
            this.citysId = this.listOfCityIds.join(',')
          }

          if (!this.isEdit && isNewCity && this.citysId != null) {
            if (this.selectedCity.length > 0) {
              let data = this.selectedCity.filter(x => x.id == this.citysId)[0];
              if (data) {
                this.selectedCity.push({ "id": data.id, "itemName": data.itemName })
                this.citysId = data.id;
              }
            }
          }
        }
      });
  }
  onChangeState(event) {
    this.rateMain911Form.patchValue({
      stateId: event.id
    });
    if (event.id) this.GetCountyByStateId(event.id, false)
  }

  onDeSelectDateFilterAll($event) {
    this.selectedState = [];
    this.selectedCounty = [];
    this.selectedCity = [];
    this.dropdownDateCountyFilter = [];
    this.dropdownDateCityFilter = [];
  }

  onDeSelectDateFilterCountyAll($event) {
    this.selectedCounty = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
  }

  onChangeCounty(event) {
    this.rateMain911Form.patchValue({
      countyId: event.id
    });
    if (event.id) this.GetCityByCountyId(event.id, false)
  }
  onChangeCity(event) {
    this.rateMain911Form.patchValue({
      cityId: event.id
    });
  }
  onDeSelectDateFilterCityAll($event) {
    this.selectedCity = [];
  }
  onChangeScope(event) {
    this.rateMain911Form.patchValue({
      scopeID: event.id
    });
  }
  onDeSelectFilterScopeAll($event) {
    this.selectedScope = [];
  }
  save() {
    this.isSubmit = true;
    let formData = new FormData();
    let data = this.rateMain911Form.value;
    if (!this.isEdit && (!this.rateMain911Form.valid || !data.taxName.trim() || !data.whatTaxed.trim() || this.selectedState.length == 0 || this.selectedScope.length == 0))
      return
    if (this.isEdit && (!this.rateMain911Form.valid || !data.taxName.trim() || !data.whatTaxed.trim() || this.selectedScope.length == 0))
      return
    if (data.citationName != null && data.citationName.trim() != "") {
      if (!data.citationURL && !this.citationDoc && !this.isEdit) {
        this.showMessage("Citation URL or Citation Document is required", 'Warning', 'warning')
        return
      }
      if (!data.citationURL && !this.citationDoc && this.isEdit) {
        if (!this.mainRate.citationFileName && !this.mainRate.citationURL) {
          this.showMessage("Citation URL or Citation Document is required", 'Warning', 'warning')
          return

        }
      }
    }
    const prevrateID = this.isDuplicate ? data.rateID : 0;
    data.rateID = this.isDuplicate ? 0 : data.rateID;
    if (this.isDuplicate) {
      data.isRecordsDuplicates = this.isDuplicate;
      data.duplicatesId = this.rateId;
    }
    else {
      data.isRecordsDuplicates = this.isDuplicate;
      data.duplicatesId = this.rateId;
    }

    if (data.rate == "") data.rate = 0;
    if (this.isEdit) { data.countyId = 0; data.cityId = 0; }
    formData.append('taxName', data.taxName.trim());
    formData.append('rateID', data.rateID);
    formData.append('prevrateID', prevrateID);
    formData.append('scopeID', data.scopeID);
    formData.append('rate', data.rate);
    formData.append('fee', data.fee);
    formData.append('effectiveDate', this.datePipe.transform(data.effectiveDate, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('endDate', this.datePipe.transform(data.endDate, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('whatTaxed', data.whatTaxed.trim());
    formData.append('notes', data.notes != null ? data.notes.trim() : data.notes);
    formData.append('showFutureLaws', data.showFutureLaws);
    formData.append('isActive', data.isActive);
    formData.append('createdBy', data.createdBy);
    formData.append('stateId', data.stateId);
    formData.append('countyId', this.countysId);
    formData.append('cityId', this.citysId);
    formData.append('citationName', data.citationName);
    formData.append('citationURL', data.citationURL == undefined ? null : data.citationURL);
    formData.append('citationDocFile', this.citationDoc);
    formData.append('citationFileName', this.fileName);
    formData.append('citationDocName', data.citationDocName);
    formData.append('auditDate', this.datePipe.transform(data.auditDate, 'yyyy-MM-dd hh:mm:ss'));
    formData.append('auditMonth', this.datePipe.transform(data.auditMonth, 'yyyy-MM'));
    this.main911Service.CreateOrUpdateMain911(this.tokenKey, formData).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            if (result['message'] == "Invalid") this.showMessage('Invalid Data', 'Error', 'error')
            else
              this.showMessage(result['message'], 'Warning', 'warning')
          } else {
            if (data.rateID > 0) {
              this.isloading = true;
              this.locationService.DeleteLocation(this.tokenKey, this.deleteLocationID).subscribe(
                data => {
                  this.isloading = false;
                },
                error => { }
              );
              this.showMessage('Rate updated successfully', 'Success', 'success');
            }
            else
              this.showMessage('Rate saved successfully', 'Success', 'success');
            this.router.navigate(['/admin/' + this.SelectedTool + '/rates']);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }

  showMessage(msg: string, msgTitle: string, msgType: string) {
    if (msgType == 'success') {
      setTimeout(() => this.toastr.success(msg, msgTitle));
    }
    else if (msgType == 'error') {
      setTimeout(() => this.toastr.error(msg, msgTitle));
    }
    else if (msgType == 'warning') {
      setTimeout(() => this.toastr.warning(msg, msgTitle));
    }
  }

  NoteVisible(isExist) {
    this.isExists = isExist;
    this.countyName = "";
    this.cityName = "";
    this.selectedCounty = [];
    this.selectedCity = [];
    this.isSubmit = false;
  }
  cancel() {
    if (this.isLocationStep) {
      let navigation = '/admin/' + this.SelectedTool + '/rates';
      this.router.navigate([navigation]);
    }
    else {
      this.isLocationStep = true;
    }
  }
  cancelEdit911Main() {
    if (!this.isEditLocationStep) {
      let navigation = '/admin/' + this.SelectedTool + '/rates';
      this.router.navigate([navigation]);
    }
    else {
      this.isEditLocationStep = false;
    }
  }

  next() {
    if (this.isExists) {
      this.isSubmit = true;
      if (this.selectedState.length == 0)
        return
      if (this.isEdit) {
        let data = this.rateMain911Form.value;
        let location: AddLocation911Model = {
          rateID: data.rateID,
          stateId: data.stateId,
          countyId: this.countysId == null ? 0 : this.countysId,
          cityId: this.citysId == null ? 0 : this.citysId,
        }
        this.countysId = "";
        this.listOfCountyIds = [];
        this.CreateLocationByRateId(location)
      }
      else this.isLocationStep = false;
    }
    else {
      this.isSubmit = true;
      if (this.selectedState.length == 0 || !this.cityValid || !this.countyValid)
        return
      this.newLocation = {
        stateId: this.selectedState[0].id,
        cityName: this.cityName.trim(),
        countyName: this.countyName.trim(),
        createdBy: this.userID
      };

      this.locationService.addNewLocation(this.tokenKey, this.newLocation).subscribe(
        result => {
          if (result['status'] == 'Success') {
            if (result['message'] != "") {
              this.showMessage(result['message'], 'Warning', 'warning');
            }
            else {
              if (result['countyId'] != 0 && result['cityId'] != 0) {
                if (this.isEdit) {
                  let location: AddLocation911Model = {
                    rateID: parseInt(this.rateId),
                    stateId: this.selectedState[0].id,
                    countyId: result['countyId'],
                    cityId: result['cityId'],
                  }
                  this.CreateLocationByRateId(location);
                }
                this.countyId = result['countyId'];
                this.cityId = result['cityId'];
              }
              this.isExists = true;
              this.isExist = 'true';
              this.showMessage('Location Saved Successfully', 'Success', 'success')
            }
          }
          else {
            this.showMessage(result['status'], 'Error', 'error');
          }
          this.selectedCounty = [];
          this.selectedCity = [];
          this.dropdownDateCountyFilter = [];
          this.dropdownDateCityFilter = [];
          this.GetCountyByStateId(this.newLocation.stateId, true);
          if (!this.isEdit && result['status'] == 'Success' && result['message'] == "" && result['countyId'] != 0 && result['cityId'] != 0) {
            this.GetCityByCountyId(result['countyId'], true);
            this.isLocationStep = false;
          }

          if (this.isEdit) this.getLocation(this.rateId)
        },
        error => {
          this.showMessage(error, 'Error', 'error');
        }
      );
    }
    this.isSubmit = false;

  }
  GetMainById(rateID) {
    this.main911Service.GetMainById(this.tokenKey, parseInt(rateID)).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.mainRate = result['main'].main911Model;
          this.fileName = result['main'].main911Model.citationFileName

          if (result['main'].main911Model.auditDate != null) { this.auditDate = new Date(result['main'].main911Model.auditDate) }
          if (result['main'].main911Model.auditMonth != null) { this.auditMonth = new Date(result['main'].main911Model.auditMonth) }
          this.rateMain911Form.patchValue({
            rateID: result['main'].main911Model.rateID,
            cityId: '',
            stateId: result['main'].location911Model[0].stateId,
            countyId: '',
            taxName: result['main'].main911Model.taxName,
            scopeID: result['main'].main911Model.scopeID,
            rate: result['main'].main911Model.rate,
            fee: result['main'].main911Model.fee,
            effectiveDate: new Date(result['main'].main911Model.effectiveDate),
            endDate: new Date(result['main'].main911Model.endDate),
            whatTaxed: result['main'].main911Model.whatTaxed,
            notes: result['main'].main911Model.notes != null ? result['main'].main911Model.notes : "",
            showFutureLaws: result['main'].main911Model.showFutureLaws,
            citationName: result['main'].main911Model.citationName != null ? result['main'].main911Model.citationName : "",
            citationURL: result['main'].main911Model.citationURL != null ? result['main'].main911Model.citationURL : "",
            citationFileName: result['main'].main911Model.citationFileName,
            citationDocName: result['main'].main911Model.citationDocName,
            auditDate: this.auditDate,
            auditMonth: this.auditMonth,
            isActive: result['main'].main911Model.isActive,

          });
          if (result['main'].main911Model.effectiveDate != "") {
            this.validDates = new Date(result['main'].main911Model.effectiveDate)
          }

          this.GetCountyByStateId(result['main'].location911Model[0].stateId, false)
          if (this.stateList.length > 0) {
            let data = this.stateList.filter(x => x.stateId == result['main'].location911Model[0].stateId)[0];
            this.selectedState.push({ "id": data.stateId, "itemName": data.name })
          }
          else
            setTimeout(() => {
              let data = this.stateList.filter(x => x.stateId == result['main'].location911Model[0].stateId)[0];
              this.selectedState.push({ "id": data.stateId, "itemName": data.name })
            }, 500);
          this.location911List = [];
          if (result['main'].location911Model.length > 0)
            this.location911List = result['main'].location911Model
          if (this.scopeList.length > 0) {
            let data = this.scopeList.filter(x => x.id == result['main'].main911Model.scopeID)[0];
            this.selectedScope.push({ "id": data.id, "itemName": data.itemName })
          }
          else
            setTimeout(() => {
              let data = this.scopeList.filter(x => x.id == result['main'].main911Model.scopeID)[0];
              this.selectedScope.push({ "id": data.id, "itemName": data.itemName })
            }, 800);
        }
      });
  }
  sort(sortBy: string) {
    if (!this.isAscending) {
      this.isAscending = true;
      this.orderBy = sortBy + ' DESC';

      if (sortBy === "CityId") {
        this.upDowNameCSS = 'fa fa-arrow-down';
        this.upDowStateNameCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "StateId") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "CountyId") {
        this.upDowNameCSS = '';
        this.upDowCountyNameCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-down';
      }
    }
    else {
      this.isAscending = false;
      this.orderBy = sortBy;

      if (sortBy === "CityId") {
        this.upDowNameCSS = 'fa fa-arrow-up';
        this.upDowStateNameCSS = '';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "StateId") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = 'fa fa-arrow-up';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "CountyId") {
        this.upDowNameCSS = '';
        this.upDowCountyNameCSS = 'fa fa-arrow-down';
        this.upDowActiveCSS = '';
      }
      else if (sortBy === "IsActive") {
        this.upDowNameCSS = '';
        this.upDowStateNameCSS = '';
        this.upDowCountyNameCSS = '';
        this.upDowActiveCSS = 'fa fa-arrow-up';
      }
    }
  }
  deleteLocation(location: Location911Model) {

    if (this.location911List.filter(x => !x.isDelete).length == 1) {
      this.showMessage("at least one location required.You can't delete this record.", 'Warning', 'warning');
    }
    else {
      location.isDelete = true
      this.deleteLocationID.push(location.locationId);
    }
  }
  pageChanged($event: any) {
    this.PageNumber = parseInt($event.page);
    this.getLocation(this.rateId)
  }
  getLocation(id) {

    this.locationService.GetAllLocationByRateId(this.tokenKey, parseInt(id)).subscribe(
      result => {
        if (result['status'] == 'Success') {
          this.location911List = [];
          this.location911List = result['location']
        }
      });
  }
  addLocation(isEditLocation) {
    this.isEditLocationStep = isEditLocation
  }
  getAllScope() {
    this.main911Service.GetAllScope(this.tokenKey).subscribe(
      data => {
        let scopeList = data['scope'];
        if (scopeList.length > 0) {
          scopeList.forEach(x => {
            this.scopeList.push({ "id": x.scopeID, "itemName": x.scopeName })
          });
        }
      },
      error => { }
    );
  }
  CreateLocationByRateId(loaction: AddLocation911Model) {
    this.locationService.CreateLocationByRateId(this.tokenKey, loaction).subscribe(
      result => {
        if (result['status'] == 'Success') {
          if (result['message'] != "") {
            if (result['message'] == "Invalid") this.showMessage('Invalid Data', 'Error', 'error')
            else
              this.showMessage(result['message'], 'Warning', 'warning');
          }
          else {
            this.isEditLocationStep = false;
            this.showMessage('Location Saved Successfully', 'Success', 'success');
            this.selectedCounty = [];
            this.selectedCity = [];
            this.dropdownDateCountyFilter = [];
            this.dropdownDateCityFilter = [];
            this.GetCountyByStateId(loaction.stateId, false);
          }
        }
        else {
          this.showMessage(result['status'], 'Error', 'error');
        }

        this.getLocation(this.rateId)
      },
      error => {
        this.showMessage(error, 'Error', 'error');
      }
    );
  }
  get cityValid() {
    if (this.pattern.test(this.cityName.trim())) return true
    else return false
  }
  get countyValid() {
    if (this.pattern.test(this.countyName.trim())) return true
    else return false
  }
  fileEvent(event, isBriefingDoc): any {
    this.citationDoc = null;
    //*-- this function gets content of uploaded file and validation -- * /    
    var file = event.target.files[0];
    let size = Math.round(file.size / 1024);
    if (size <= 21000) {
      var allowedExtensions = ["pdf", "PDF"];
      let fileType = file.name.split('.').pop();
      if (!this.isInArray(allowedExtensions, fileType)) {
        this.showMessage('You have selected an invalid file type.System supports following file formats: PDF.', 'Error', 'error');
        if (isBriefingDoc)
          this.isErrorOnBriefingDoc = true;
        else
          this.isErrorOnSupportedDoc = true;

        window.scrollTo(0, 0);
      }
      else {
        if (isBriefingDoc) {
          this.isErrorOnBriefingDoc = false;
          this.citationDoc = file;
          this.fileName = file.name;
          this.rateMain911Form.controls.citationURL.setValue("")
          this.showMessage("For citation last modified detail will be saved to database.", 'Warning', 'warning')
        }
        else
          this.isErrorOnSupportedDoc = false;
      }
    }
    else {
      this.showMessage('You have selected a larger file. supports file upto 20MB.', 'Error', 'error');
      if (isBriefingDoc)
        this.isErrorOnBriefingDoc = true;
      else
        this.isErrorOnSupportedDoc = true;
      window.scrollTo(0, 0);
    }
  }
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }


  get citationNameValue() {
    let value = this.rateMain911Form.value as any;;
    if (value.citationName == null || value.citationName.trim() == "") {
      this.fileName = null;
      this.citationDoc = null;
      this.rateMain911Form.controls['citationURL'].setValue("");
      this.rateMain911Form.controls['citationURL'].disable();
      document.getElementById("citationDocument")['value'] = "";
      return true;
    }
    else {
      this.rateMain911Form.controls['citationURL'].enable();
      return false;
    }
  }
  get validDate() {
    if (this.rateMain911Form.controls.effectiveDate.value != "") return this.rateMain911Form.controls.effectiveDate.value
    else return new Date();
  }
  PreviewImage() {
    this.setAPIUrl();
    if (this.isEdit && this.mainRate && this.mainRate.citationName) {
      if (this.mainRate.citationDocName && !this.citationDoc) window.open(this.AppUrl + '\CitationDocument\\' + this.mainRate.citationDocName, '_blank');
      else if (this.citationDoc) {
        let pdffile_url = URL.createObjectURL(this.citationDoc);
        window.open(pdffile_url, '_blank')
      }

    } else {
      if (this.citationDoc) {
        let pdffile_url = URL.createObjectURL(this.citationDoc);
        window.open(pdffile_url, '_blank')
      }
    }

  }
  setAPIUrl() {
    if (sessionStorage.getItem("BaseURL") != null && sessionStorage.getItem("BaseURL") != "") {
      this.AppUrl = sessionStorage.getItem("BaseURL") || '';
    }
  }
  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  //New Code Added

  removeElemetninArray(array, element) {
    return array.filter(e => e !== element);
  }


  onSelectCounty($event) {
    this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
    this.listOfCountyIds.push($event.id);
    this.countysId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countysId = this.listOfCountyIds.join(',')
    }
    var countyID = $event.id.toString();
    this.GetCityByCountyId(countyID, false);
  }

  onDeSelectCounty($event) {

    if (this.listOfCountyIds != null) {
      if (this.listOfCountyIds.length > 0) {
        this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event.id)
      }
    }
    this.countysId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countysId = this.listOfCountyIds.join(',')
    }
    if (this.listOfCountyIds.length == 0) {
      this.listOfCityIds = [];
      this.selectedCity = [];
      this.dropdownDateCityFilter = [];
      this.cityList = [];
    }
    else
      var CountyID = $event.id.toString()
    this.GetCityByCountyIdforRemoveCity(CountyID, false);
  }

  onSelectAllCounty($event) {

    this.isShowprogressSpinner = true;
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countysId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countysId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countysId, false);
  }

  onDeSelectAllCounty($event) {
    this.isShowprogressSpinner = true;
    this.listOfCountyIds = [];
    this.selectedCity = [];
    this.dropdownDateCityFilter = [];
  }

  onSelectCity($event) {
    this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
    this.listOfCityIds.push($event.id);
    this.citysId = ' ';
    if (this.listOfCityIds.length > 0) {
      this.citysId = this.listOfCityIds.join(',')
    }
  }

  onDeSelectCity($event) {

    if (this.listOfCityIds != null) {
      if (this.listOfCityIds.length > 0) {
        this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event.id)
      }
    }
    if (this.listOfCityIds.length > 0) {
      this.citysId = this.listOfCityIds.join(',')
    }
  }

  onSelectAllCity($event) {

    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
  }

  onDeSelectAllCity($event) {
    this.listOfCityIds = [];
    this.selectedCity = [];
  }

  GetCityByCountyIdforRemoveCity(countyId, isNewCity) {
    const arr = countyId.split(',');
    this.cityService.GetCityByCountyId(this.tokenKey, arr).subscribe(
      result => {
        if (result['status'] == 'Success') {

          if (this.cityList.length > 0 && this.selectedCity.length > 0 && this.listOfCityIds.length > 0) {
            this.cityListForRemove = [];
            this.cityListForRemoveNew = [];
            this.cityListForRemove = result['city'];

            this.cityListForRemove.forEach(x => {
              this.cityListForRemoveNew.push({ "id": x.cityId, "itemName": x.cityName })
            });

            for (let iCounter = 0; iCounter < this.cityListForRemoveNew.length; iCounter++) {
              let index: number = -1;

              index = this.cityList.findIndex(y => y.cityId == this.cityListForRemoveNew[iCounter].id)
              if (index !== -1) {
                this.cityList.splice(index, 1);
              }

              index = this.selectedCity.findIndex(y => y.id == this.cityListForRemoveNew[iCounter].id);
              if (index !== -1) {
                this.selectedCity.splice(index, 1);
              }

              index = this.dropdownDateCityFilter.findIndex(y => y.id == this.cityListForRemoveNew[iCounter].id);
              if (index !== -1) {
                this.dropdownDateCityFilter.splice(index, 1);
              }
              this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, this.cityListForRemoveNew[iCounter].id)
            }
          }
          this.citysId = ' ';
          if (this.listOfCityIds.length > 0) {
            this.citysId = this.listOfCityIds.join(',')
          }
        }
      }
    );
  }

  onFilterSelectAllCounty($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
          this.listOfCountyIds.push($event[iCounter].id);
        }
        else {
          this.listOfCountyIds.push($event[iCounter].id);
        }
      }
    }
    this.countysId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countysId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countysId, false);
  }

  onFilterDeSelectAllCounty($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCountyIds != null) {
        if (this.listOfCountyIds.length > 0) {
          this.listOfCountyIds = this.removeElemetninArray(this.listOfCountyIds, $event[iCounter].id)
        }
      }
    }
    this.countysId = ' ';
    if (this.listOfCountyIds.length > 0) {
      this.countysId = this.listOfCountyIds.join(',')
    }
    this.GetCityByCountyId(this.countysId, false);
  }

  onFilterSelectAllCity($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
          this.listOfCityIds.push($event[iCounter].id);
        }
        else {
          this.listOfCityIds.push($event[iCounter].id);
        }
      }
    }
  }

  onFilterDeSelectAllCity($event) {
    for (let iCounter = 0; iCounter < $event.length; iCounter++) {
      if (this.listOfCityIds != null) {
        if (this.listOfCityIds.length > 0) {
          this.listOfCityIds = this.removeElemetninArray(this.listOfCityIds, $event[iCounter].id)
        }
      }
    }
  }
}